import Ft from '../first/ft';
import FtRapi from '../first/ftRapi';
import FtAppInfo from '../first/ftAppInfo';
import FtRapiResult from '../first/ftRapiResult';
import type { FtRapiCallArgs } from '../first/ftTypes';

//********************************************************************
// App wide utilities
export default class Ut {

    //====================================================================
    // properties

    //- server info -----
    static svrVer: string = "";

    //- logged-in user -----
    static user_id: number = 0;
    static display_name: string = "";
    static role_id: number = 0;                     // user_auth_role_type id
    static user_roles: number[] = [];               // roles user is authorized for

    //====================================================================
    // static api

    /// check if user and server info is loaded, load if not
    static checkUser( complete?: () => void ): void
    {
        //- check don't load more than once -----
        if ( Ut.svrVer == "" ) Ut.loadUser( complete );
        else if ( complete ) complete();
    }

    /// force loading user and server info
    static loadUser( complete?: () => void ): void
    {
        //- load server and user -----
        Ut.user_id = 0;
        FtRapi.callRead( 'serverExist', 0, {
            success: ( { message }, data ) => Ut.svrVer = data[0],
            complete: () => {
                FtRapi.callRead( 'authLoginCheck', 0, {
                    success: ( { message }, data ) => {
                        Ut.user_id      = data[0].id;
                        Ut.display_name = data[0].disp_name;
                        Ut.role_id      = data[2];
                        Ut.user_roles   = data[3];
                    },
                    error: () => { // clear if not logged in
                        Ut.user_id      = 0;
                        Ut.display_name = "";
                        Ut.role_id      = 0;
                        Ut.user_roles   = [];
                    },
                    complete: () => {
                        if ( complete ) complete();
                    },
                });
            }
        });
    }

    /// test if current user has specified role
    static roleTest( role: number, role2?: number, role3?: number, role4?: number, role5?: number, role6?: number ): boolean
    {
        if ( Ut.user_roles.includes( role ) ) return true;
        if ( role2 != undefined && Ut.user_roles.includes( role2 ) ) return true;
        if ( role3 != undefined && Ut.user_roles.includes( role3 ) ) return true;
        if ( role4 != undefined && Ut.user_roles.includes( role4 ) ) return true;
        if ( role5 != undefined && Ut.user_roles.includes( role5 ) ) return true;
        if ( role6 != undefined && Ut.user_roles.includes( role6 ) ) return true;
        return false;
    }

    /// open url in new tab, url is relative to server
    ///
    /// Example: Ut.openUrlSvr( 'myreport' );
    static openUrlSvr( url: string, args: any = {} ): void
    {
        Ft.openUrl( FtAppInfo.baseUrl + url, args );
    }

    /// open url in new tab, url is relative to server
    ///
    /// Example: Ut.openReport( 'myreport', prms );
    static openReport( url: string, parms: any = {} ): void
    {
        Ft.openUrl( FtAppInfo.baseUrl + url, { p: Ft.objToJsonBase64( parms ) } );
    }
}
