import UserTypeBase from './base/userTypeBase';

//********************************************************************
// UserType class
// @FTGEN_PROGRESS: busy
export default class UserType extends UserTypeBase
{
    //====================================================================
    // static data members

    // #FTGEN_CONSTS_A#
    static UT_ADMIN = 1;
    static UT_GUEST = 2;
    static UT_MEMBER = 3;
    static UT_OFFICE = 4;
    // #FTGEN_CONSTS_Z#

    //====================================================================
    // init
    
    constructor()
    {
        super();
    }
}
