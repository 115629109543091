import React, { useState, useEffect } from 'react';
import type { FtPageProps, FtDgAction } from '../first/ftTypes';
import Ft from '../first/ft';
import FtRapi from '../first/ftRapi';
import FtFrame from '../first/ftFrame';
import FtCanvas from '../first/ftCanvas';
import FtCon from '../first/ftCon';
import FtSpacer from '../first/ftSpacer';
import FtForm from '../first/ftForm';
import FtText from '../first/ftText';
import FtIcon from '../first/ftIcon';
import FtFmSubmit from '../first/ftFmSubmit';
import FtFmTextField from '../first/ftFmTextField';
import FtFmTextareaField from '../first/ftFmTextareaField';
import FtFmPasswordField from '../first/ftFmPasswordField';
import FtFmIntegerField from '../first/ftFmIntegerField';
import FtFmDecimalField from '../first/ftFmDecimalField';
import FtFmDateField from '../first/ftFmDateField';
import FtFmTimeField from '../first/ftFmTimeField';
import FtFmCheckboxField from '../first/ftFmCheckboxField';
import FtFmDropdownField from '../first/ftFmDropdownField';
import FtFmSearchField from '../first/ftFmSearchField';
import FtButton from '../first/ftButton';
import FtDataGrid from '../first/ftDataGrid';
import { ftBusy } from '../first/ftBusy';
import { ftPopupMessage } from '../first/ftPopupMessage';
import { ftPopupConfirm } from '../first/ftPopupOptions';
import FtSs from '../first/ftSs';
import Th from '../general/th';
import Ut from '../general/ut';

import GenLookup      from '../models/genLookup';
import DispositionRec from '../models/dispositionRec';

//********************************************************************
// PageDispositionDashboard
// @FTGEN_PROGRESS: busy
// ...todo: specify props here...
export default function PageDispositionDashboard( { navigation, options }: FtPageProps ): JSX.Element
{
    //====================================================================
    // hooks: no conditions here

    //- hooks: state -----
    const duration = 300; // 5mins
    const nav = Ft.useNav( navigation );
    const [ popup                  , setPopup                  ] = useState( <></> ); // placeholder for any popup message/options
    const [ busy                   , setBusy                   ] = useState( false );
    const [ lkpSources             , setLkpSources             ] = useState<GenLookup[]>( [] );
    const [ recs                   , setRecs                   ] = useState<DispositionRec[]>( [] );
    const [ topTotals              , setTopTotals              ] = useState<any>( {} );  // top of dashboard totals
    const [ colTotals              , setColTotals              ] = useState<any>( {} );  // top of column totals
    const [ updateDtm              , setUpdateDtm              ] = useState<string>( "" );
    const [ prm                    , setPrm                    ] = useState<any>( { period_id: 1 } );
    const [ timerCnt               , setTimerCnt               ] = useState<Number>( duration );
    const [ showLoading            , setShowLoading            ] = useState<boolean>( false );
    const [ lkpPeriods             , setLkpPeriods             ] = useState<GenLookup[]>( [
        ( new GenLookup ).copyFrom( { id: 1, name: 'This Month'    } ),
        ( new GenLookup ).copyFrom( { id: 2, name: 'Last Month'    } ),
        ( new GenLookup ).copyFrom( { id: 3, name: 'Two Month Ago' } ),
    ] );

    //- hooks: effect/startup -----
    useEffect( onLoad, [] );

    //====================================================================
    // render

    if ( busy ) return ftBusy( { popup: popup } );

    const dashStl: any = {
        ss: '12',
        md: 'rw',
        lt: 'a,c',
        bor: '1,#f26522',
    };

    const formStl: any = {
        ...Th.form,
        ss: '12',
        md: 'rw',
        lt: 'a,a',
        bor: '1,#f26522',
    };

    const txtLbl: any = {
        ss: '8',
        txtDescr: '20,WCO#273891',
        bkgClr: '#cccccc',
        pad: 4,
    };
    const txtLblH: any = {
        ss: '8',
        txtDescr: '20,WCO#273891',
        bkgClr: '#f08f5e',
        pad: 4,
    };

    const txtNum: any = {
        ss: '4',
        txtDescr: '20,WCO#000000',
        bkgClr: '#eeeeee',
        pad: 4,
    };

    const txtNumH: any = {
        ss: '4',
        txtDescr: '20,WCO#000000',
        bkgClr: '#f08f5e',
        pad: 4,
    };

    const txtLeadHd1: any = {
        ss: '4',
        txtDescr: '16,WCO#000000',
        pad: 2,
        bkgClr: '#f0a681',
    };

    const txtLeadHd2: any = {
        ss: '4',
        txtDescr: '16,WCO#273891',
        pad: 2,
        bkgClr: '#cccccc',
    };

    const txtAmntHd1: any = {
        ss: '1',
        lt: 'z,a',
        txtDescr: '16,O#000000',
        pad: 2,
        bkgClr: '#f0a681',
    };

    const txtAmntHd2: any = {
        ss: '1',
        lt: 'z,a',
        txtDescr: '16,O#273891',
        pad: 2,
        bkgClr: '#cccccc',
    };

    const txtLead: any = {
        ss: '4',
        txtDescr: '16,O#000000',
        bkgClr: '#eeeeee',
        pad: 2,
    };

    const txtAmnt: any = {
        ss: '1',
        lt: 'z,a',
        txtDescr: '16,#000000',
        bkgClr: '#eeeeee',
        pad: 2,
    };

    const divLd: any = {
        ss: '12',
        h: 5,
        bkgClr: '#dddddd',
    };

    const ddnStl: any = {
        ...Th.field,
        ss     : '8',
        labSs  : '4',
    };

    const colStl: any = {
        md: 'rw',
        lt: 'a,a',
        ss: '3',
    };

    const rowStl: any = {
        md: 'rw',
        lt: 'a,a',
        ss: '12',
    };

    return (<FtCanvas {...Th.page}>
        {popup}

        <FtCon ss='12' md='rw' lt='z,a' >
            <FtText text={`Next refresh : ${timerCnt}`} txtDescr='20,#000000;Helvetica' show={!showLoading} />
            <FtText text='Reloading...' txtDescr='20,#000000;Helvetica' show={showLoading} />
            <FtIcon descr='24,#404040;fa-hourglass' pad='0,10,0,10' show={showLoading} />
        </FtCon>
        
        <FtFrame text={`Last updated: ${updateDtm}`} {...Th.frame} ss='12|12|12|12|12' bkgClr='#ffffff' >

            <FtForm {...formStl} models={ { m: prm } }>

                <FtCon {...colStl}>
                    <FtText {...txtLblH} text='Spend' />
                    <FtText {...txtNumH} text={`R ${Ft.dec2( topTotals!.spend )}`} />

                    <FtText {...txtLbl} text='Total Signed' />
                    <FtText {...txtNum} text={topTotals!.client_count} />

                    <FtText {...txtLbl} text='Total Leads' />
                    <FtText {...txtNum} text={topTotals!.lead_count} />
                </FtCon>

                <FtCon {...colStl}>
                    <FtText {...txtLbl} text='CVR' />
                    <FtText {...txtNum} text={`${Ft.dec2( topTotals?.conversion_rate )} %`} />

                    <FtText {...txtLbl} text='Overall CPL' />
                    <FtText {...txtNum} text={`R ${Ft.dec2( topTotals!.overall_cpl )}`} />

                    <FtText {...txtLbl} text='Overall CPA' />
                    <FtText {...txtNum} text={`R ${Ft.dec2( topTotals!.overall_cpa )}`} />
                </FtCon>

                <FtCon {...colStl}>
                    <FtText {...txtLbl} text='Revenue' />
                    <FtText {...txtNum} text={`R ${Ft.dec2( topTotals!.revenue )}`} />

                    <FtText {...txtLbl} text='Revenue / client' />
                    <FtText {...txtNum} text={`R ${Ft.dec2( topTotals!.ave_revenue_per_cus )}`} />

                    <FtText {...txtLbl} text='Gross Profit' />
                    <FtText {...txtNum} text={`R ${Ft.dec2( topTotals!.gross_profit )}`} />
                </FtCon>
                <FtCon {...colStl}>
                    <FtFmDropdownField name='m.period_id'  label='Period'  {...ddnStl}  ftreq data={lkpPeriods} onChange={onPeriodChange} />
                </FtCon>
            </FtForm>
            <FtSpacer />

            <FtCon {...dashStl}>

                <FtText {...txtLeadHd1} text='Totals' />
                <FtText {...txtAmntHd1} text={colTotals.lead_count} />
                <FtText {...txtAmntHd1} text={colTotals.client_count} />
                <FtText {...txtAmntHd1} text={`${Ft.dec2( colTotals.conversion_rate )} %`} />
                <FtText {...txtAmntHd1} text={`R ${Ft.dec2( colTotals.revenue )}`} />
                <FtText {...txtAmntHd1} text={`R ${Ft.dec2( colTotals.ave_revenue_per_cus )}`} />
                <FtText {...txtAmntHd1} text={`R ${Ft.dec2( colTotals.spend )}`} />
                <FtText {...txtAmntHd1} text={`R ${Ft.dec2( colTotals.cost_per_action )}`} />
                <FtText {...txtAmntHd1} text={`R ${Ft.dec2( colTotals.gross_profit )}`} />

                <FtText {...txtLeadHd2} text='Lead Source' />
                <FtText {...txtAmntHd2} text='Leads' />
                <FtText {...txtAmntHd2} text='Clients' />
                <FtText {...txtAmntHd2} text='CVR' />
                <FtText {...txtAmntHd2} text='Total Revenue' />
                <FtText {...txtAmntHd2} text='ARPC' />
                <FtText {...txtAmntHd2} text='Total Spend' />
                <FtText {...txtAmntHd2} text='CPA' />
                <FtText {...txtAmntHd2} text='Gross Profit' />

                {recs.map( r => (<FtCon key={r.id} {...rowStl}>
                    <FtText {...txtLead} text={lkpSources.find( ( x: any ) => x.id == r.lead_source_id )?.name} />             
                    <FtText {...txtAmnt} text={r.lead_count} />
                    <FtText {...txtAmnt} text={r.client_count} />
                    <FtText {...txtAmnt} text={`${Ft.dec2( r.conversion_rate )} %`} />
                    <FtText {...txtAmnt} text={`R ${Ft.dec2( r.revenue )}`} />
                    <FtText {...txtAmnt} text={`R ${Ft.dec2( r.ave_revenue_per_cus )}`} />
                    <FtText {...txtAmnt} text={`R ${Ft.dec2( r.spend )}`} />
                    <FtText {...txtAmnt} text={`R ${Ft.dec2( r.cost_per_action )}`} />
                    <FtText {...txtAmnt} text={`R ${Ft.dec2( r.gross_profit )}`} />
                </FtCon>))}

            </FtCon>
        </FtFrame>

    </FtCanvas>);

    //====================================================================
    // event handlers

    function onLoad(): void
    {
        reload();
        onTimer();
    }

    function reload(): void
    {
        setShowLoading( true );
        FtRapi.callList( 'DispositionData', {
            urlParms: { 'period_id': prm.period_id },
            success: ( result, data ) => {
                setRecs( data[0] );
                setLkpSources( data[1] );
                setUpdateDtm( data[2] );

                //- calc below -----
                const sum: DispositionRec = new DispositionRec;
                sum.copyFrom( data[3] );
                const lq: any = Ft.lq( data[0] );
                const top: any = {
                    spend               : sum.spend,
                    client_count        : sum.client_count,
                    lead_count          : sum.lead_count,
                    conversion_rate     : sum.conversion_rate,
                    overall_cpl         : Ft.D0(), // TODO
                    overall_cpa         : sum.cost_per_action,
                    revenue             : sum.revenue,
                    ave_revenue_per_cus : sum.ave_revenue_per_cus,
                    gross_profit        : sum.gross_profit,
                };
                setTopTotals( top );

                const col: any = {
                    lead_count          : top.lead_count,
                    client_count        : top.client_count,
                    conversion_rate     : top.conversion_rate,
                    revenue             : top.revenue,
                    ave_revenue_per_cus : top.ave_revenue_per_cus,
                    spend               : top.spend,
                    cost_per_action     : top.overall_cpa,
                    gross_profit        : top.gross_profit,
                };
                setColTotals( col );
            },
            complete: () => setShowLoading( false ),
        });
    }

    function onPeriodChange( newId: Number ): void
    {
        prm.period_id = newId;
        reload();
    }

    function onTimer(): void
    {
        setTimeout( () => {
            setTimerCnt( x => { 
                if ( +x < 1 )
                    reload();
                return ( +x > 0 ) ? +x - 1 : duration
            } );
            onTimer();
        }, 1000 );
    }

}

/*
=== OLD CODE =====================================================

                <FtText {...txtLeadHd1} text='Totals' />
                <FtText {...txtAmntHd1} text='0' />
                <FtText {...txtAmntHd1} text='0' />
                <FtText {...txtAmntHd1} text='0.00 %' />
                <FtText {...txtAmntHd1} text='R 0.00' />
                <FtText {...txtAmntHd1} text='R 0.00' />
                <FtText {...txtAmntHd1} text='R 0.00' />
                <FtText {...txtAmntHd1} text='R 0.00' />
                <FtText {...txtAmntHd1} text='R 0.00' />

                <FtText {...txtLeadHd2} text='Lead Source' />
                <FtText {...txtAmntHd2} text='Leads' />
                <FtText {...txtAmntHd2} text='Clients' />
                <FtText {...txtAmntHd2} text='CVR' />
                <FtText {...txtAmntHd2} text='Total Revenue' />
                <FtText {...txtAmntHd2} text='ARPC' />
                <FtText {...txtAmntHd2} text='Total Spend' />
                <FtText {...txtAmntHd2} text='CPA' />
                <FtText {...txtAmntHd2} text='Gross Profit' />

                <FtText {...txtLead} text='DR Google Ads' />             
                <FtText {...txtAmnt} text='0' />
                <FtText {...txtAmnt} text='0' />
                <FtText {...txtAmnt} text='0.00 %' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />

                <FtText {...txtLead} text='DR Meta Ads' />               
                <FtText {...txtAmnt} text='0' />
                <FtText {...txtAmnt} text='0' />
                <FtText {...txtAmnt} text='0.00 %' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />

                <FtText {...txtLead} text='DR TikTok Ads' />             
                <FtText {...txtAmnt} text='0' />
                <FtText {...txtAmnt} text='0' />
                <FtText {...txtAmnt} text='0.00 %' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />

                <FtText {...txtLead} text='DR Top 10' />                 
                <FtText {...txtAmnt} text='0' />
                <FtText {...txtAmnt} text='0' />
                <FtText {...txtAmnt} text='0.00 %' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />

                <FtCon {...divLd} />

                <FtText {...txtLead} text='SH Google Ads' />             
                <FtText {...txtAmnt} text='0' />
                <FtText {...txtAmnt} text='0' />
                <FtText {...txtAmnt} text='0.00 %' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />

                <FtText {...txtLead} text='SH Meta Ads' />               
                <FtText {...txtAmnt} text='0' />
                <FtText {...txtAmnt} text='0' />
                <FtText {...txtAmnt} text='0.00 %' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />

                <FtText {...txtLead} text='SH Top 10' />                 
                <FtText {...txtAmnt} text='0' />
                <FtText {...txtAmnt} text='0' />
                <FtText {...txtAmnt} text='0.00 %' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />

                <FtText {...txtLead} text='SH TikTok Ads' />             
                <FtText {...txtAmnt} text='0' />
                <FtText {...txtAmnt} text='0' />
                <FtText {...txtAmnt} text='0.00 %' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />

                <FtCon {...divLd} />

                <FtText {...txtLead} text='DR Organic (Direct)' />       
                <FtText {...txtAmnt} text='0' />
                <FtText {...txtAmnt} text='0' />
                <FtText {...txtAmnt} text='0.00 %' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />

                <FtText {...txtLead} text='DR Organic (Search)' />       
                <FtText {...txtAmnt} text='0' />
                <FtText {...txtAmnt} text='0' />
                <FtText {...txtAmnt} text='0.00 %' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />

                <FtCon {...divLd} />

                <FtText {...txtLead} text='SH Organic (Direct)' />       
                <FtText {...txtAmnt} text='0' />
                <FtText {...txtAmnt} text='0' />
                <FtText {...txtAmnt} text='0.00 %' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />

                <FtText {...txtLead} text='SH Organic (Search)' />       
                <FtText {...txtAmnt} text='0' />
                <FtText {...txtAmnt} text='0' />
                <FtText {...txtAmnt} text='0.00 %' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />

                <FtCon {...divLd} />

                <FtText {...txtLead} text='DR Affiliates (Inbound)' />   
                <FtText {...txtAmnt} text='0' />
                <FtText {...txtAmnt} text='0' />
                <FtText {...txtAmnt} text='0.00 %' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />

                <FtText {...txtLead} text='DR Affiliates (Outbound)' />  
                <FtText {...txtAmnt} text='0' />
                <FtText {...txtAmnt} text='0' />
                <FtText {...txtAmnt} text='0.00 %' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />

                <FtCon {...divLd} />

                <FtText {...txtLead} text='SH Referral (AfrForum)' />    
                <FtText {...txtAmnt} text='0' />
                <FtText {...txtAmnt} text='0' />
                <FtText {...txtAmnt} text='0.00 %' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />

                <FtText {...txtLead} text='SH Referral (DR)' />          
                <FtText {...txtAmnt} text='0' />
                <FtText {...txtAmnt} text='0' />
                <FtText {...txtAmnt} text='0.00 %' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />

                <FtCon {...divLd} />

                <FtText {...txtLead} text='DR AI' />                     
                <FtText {...txtAmnt} text='0' />
                <FtText {...txtAmnt} text='0' />
                <FtText {...txtAmnt} text='0.00 %' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />

                <FtText {...txtLead} text='SH AI' />                     
                <FtText {...txtAmnt} text='0' />
                <FtText {...txtAmnt} text='0' />
                <FtText {...txtAmnt} text='0.00 %' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />

                <FtText {...txtLead} text='DR Family Friends' />         
                <FtText {...txtAmnt} text='0' />
                <FtText {...txtAmnt} text='0' />
                <FtText {...txtAmnt} text='0.00 %' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />

                <FtText {...txtLead} text='DR Email' />                  
                <FtText {...txtAmnt} text='0' />
                <FtText {...txtAmnt} text='0' />
                <FtText {...txtAmnt} text='0.00 %' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />

                <FtText {...txtLead} text='SH Email' />                  
                <FtText {...txtAmnt} text='0' />
                <FtText {...txtAmnt} text='0' />
                <FtText {...txtAmnt} text='0.00 %' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />

                <FtText {...txtLead} text='DR Phone In' />               
                <FtText {...txtAmnt} text='0' />
                <FtText {...txtAmnt} text='0' />
                <FtText {...txtAmnt} text='0.00 %' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />

                <FtText {...txtLead} text='DR LiveChat' />               
                <FtText {...txtAmnt} text='0' />
                <FtText {...txtAmnt} text='0' />
                <FtText {...txtAmnt} text='0.00 %' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />

                <FtText {...txtLead} text='DR WhatsApp' />               
                <FtText {...txtAmnt} text='0' />
                <FtText {...txtAmnt} text='0' />
                <FtText {...txtAmnt} text='0.00 %' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />

                <FtText {...txtLead} text='DR Manual' />                 
                <FtText {...txtAmnt} text='0' />
                <FtText {...txtAmnt} text='0' />
                <FtText {...txtAmnt} text='0.00 %' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />

                <FtText {...txtLead} text='DR Walk In' />                
                <FtText {...txtAmnt} text='0' />
                <FtText {...txtAmnt} text='0' />
                <FtText {...txtAmnt} text='0.00 %' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />
                <FtText {...txtAmnt} text='R 0.00' />

*/