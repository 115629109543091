import DispositionRecBase from './base/dispositionRecBase';

//********************************************************************
// DispositionRec class
// @FTGEN_PROGRESS: busy
export default class DispositionRec extends DispositionRecBase
{
    //====================================================================
    // static data members

    // #FTGEN_CONSTS_A#
    // #FTGEN_CONSTS_Z#

    //====================================================================
    // init
    
    constructor()
    {
        super();
    }
}
