import React, { useState, useEffect } from 'react';
import type { FtPageProps, FtDgAction } from '../first/ftTypes';
import Ft from '../first/ft';
import FtRapi from '../first/ftRapi';
import FtFrame from '../first/ftFrame';
import FtCanvas from '../first/ftCanvas';
import FtCon from '../first/ftCon';
import FtSpacer from '../first/ftSpacer';
import FtForm from '../first/ftForm';
import FtText from '../first/ftText';
import FtFmSubmit from '../first/ftFmSubmit';
import FtFmTextField from '../first/ftFmTextField';
import FtFmTextareaField from '../first/ftFmTextareaField';
import FtFmPasswordField from '../first/ftFmPasswordField';
import FtFmIntegerField from '../first/ftFmIntegerField';
import FtFmDecimalField from '../first/ftFmDecimalField';
import FtFmDateField from '../first/ftFmDateField';
import FtFmTimeField from '../first/ftFmTimeField';
import FtFmCheckboxField from '../first/ftFmCheckboxField';
import FtFmDropdownField from '../first/ftFmDropdownField';
import FtFmSearchField from '../first/ftFmSearchField';
import FtButton from '../first/ftButton';
import FtDataGrid from '../first/ftDataGrid';
import { ftBusy } from '../first/ftBusy';
import { ftPopupMessage } from '../first/ftPopupMessage';
import { ftPopupConfirm } from '../first/ftPopupOptions';
import FtSs from '../first/ftSs';
import Th from '../general/th';
// import Ut from '../general/ut';

//********************************************************************
// PageTest
// @FTGEN_PROGRESS: done
// ...todo: specify props here...
export default function PageTest( { navigation, options }: FtPageProps ): JSX.Element
{
    //====================================================================
    // hooks: no conditions here
    
    //- hooks: state -----
    const nav = Ft.useNav( navigation );
    //const { parameter } = nav.params ?? { parameter };    // unpack parameters passed from calling page
    const [ popup                  , setPopup                  ] = useState( <></> ); // placeholder for any popup message/options
    const [ busy                   , setBusy                   ] = useState( false );

    //- hooks: effect/startup -----
    // useEffect( onLoad, [] );


    //====================================================================
    // render
    
    // TODO if ( busy || Ft.eAny( mdlDataTest, mdlCatClass ) ) return ftBusy( { popup: popup } );

    return (<FtCanvas {...Th.page}>
        {popup}

        <FtCon ss='12' md='rw' lt='z,a' marB={15}>
            <FtText ss='6' txtDescr='24,WCO#273891' text='Hello World!' />
        </FtCon>
    </FtCanvas>);

    //====================================================================
    // event handlers
    


    function onLoad(): void 
    {

    }

}
