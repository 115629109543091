import React, { useState, useEffect } from 'react';
import { Appbar } from 'react-native-paper';
import type { FtPageProps } from '../first/ftTypes';
import Ft from '../first/ft';
import FtAppInfo from '../first/ftAppInfo';
import FtRapi from '../first/ftRapi';
import FtImage from '../first/ftImage';
import { ftPopupMessage } from '../first/ftPopupMessage';
import { ftBusy } from '../first/ftBusy';
import Ut from '../general/ut';

import im_logo from '../../assets/logo-heading.jpg';

//********************************************************************
// FragHeader
// @FTGEN_PROGRESS: done
// Description:
// App wise page header
export default function FragHeader( { navigation, options }: FtPageProps ): JSX.Element  
{
    //====================================================================
    // hooks: no conditions here

    //- hooks: state -----
    const nav = Ft.useNav( navigation );
    const [ popup   , setPopup   ] = useState( <></> );
    const [ visible , setVisible ] = useState( false );
    const [ busy    , setBusy    ] = useState( false );

    useEffect( onLoad, [] );

    //====================================================================
    // render

    if ( busy ) return ftBusy( { popup: popup } );

    const txtClr = "#2f2b56";
    const headStr
        = ( !!Ut.display_name ? `[ ${Ut.display_name} ] ` : '[ Guest ] ' )
        + `App: v${Ft.prettyVersionExt( FtAppInfo.version )}, Server: v${Ft.prettyVersionExt( Ut.svrVer )}`;

    return (<>
        <Appbar style={{ backgroundColor: "#ffffff", padding: 0 }}>
            <FtImage src={im_logo} w={202} />
            {nav.canPop() ? <Appbar.BackAction color='#2f2b56' onPress={() => nav.pop()} /> : null}
            <Appbar.Content title={options.title} color={txtClr} />
            {!busy && (<Appbar.Content title={headStr} color={txtClr} titleStyle={{ textAlign: 'right'}} style={{ paddingRight: 20 }} />)}
            {!Ft.e(Ut.user_id) && !busy && (<Appbar.Action icon="home" color={txtClr} onPress={onTapHome} />)}
            {!Ft.e(Ut.user_id) && (<Appbar.Action icon={busy ? "timer-sand" : "exit-run"} color={txtClr} onPress={logout} />)}
        </Appbar>
    </>);

    //====================================================================
    // event handlers

    function onLoad(): void 
    {
        setBusy( true );
        Ut.checkUser( () => setBusy( false ) );
    }

    function onTapHome(): void
    {
        nav.popAllTo( 'Menu' );
    }

    function logout(): void
    {
        setBusy( true );
        FtRapi.callNew( 'authLogout', {
            success: ( { message }, data ) => Ut.loadUser( () => nav.popAllTo( 'AuthLogin' ) ),
            error: ( { message } ) => ftPopupMessage( setPopup, 'Server Error', message, { onClose: () => nav.popAllTo( 'Menu' ) } ),
        } );
    }
}
